<template>
    <svg
        width="30px"
        height="33px"
        viewBox="0 0 30 33"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_login_shield-check</title>
        <defs>
            <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="linearGradient-1-shield-check"
            >
                <stop stop-color="#ECCF8B" offset="0%"></stop>
                <stop stop-color="#A28442" offset="100%"></stop>
            </linearGradient>
            <path
                d="M46.5296502,19.3919344 C46.435506,18.4675879 45.7542743,17.7103009 44.8450082,17.5192125 C43.0016598,17.2294189 41.1809827,16.8103007 39.3964605,16.2649619 C37.6489174,15.5482928 36.0102995,14.5905228 34.5283066,13.4195323 C33.7511773,12.8601559 32.7034718,12.8601559 31.9263424,13.4195323 C30.5234519,14.717021 28.8472289,15.6830869 27.0212649,16.2465001 C25.3305214,16.9455833 23.54446,17.3870423 21.7227195,17.5561361 C20.7935068,17.7062817 20.09757,18.4884877 20.0565394,19.4288581 C20.0565394,19.4288581 20,24.1285478 20,28.2651516 C20,35.7537316 28.8362936,43 33.2555942,43 C37.673741,43 45.1634748,37.8883807 46.361186,28.3782303 C46.6611908,22.7612185 46.4534952,19.4103962 46.4534952,19.4103962 L46.5296502,19.3907805 L46.5296502,19.3919344 Z M41.7180357,25.1762566 L32.4871205,33.8821635 C32.0717293,34.277939 31.4371039,34.3402477 30.9524809,34.0321659 L30.7101693,33.8256242 L25.6170119,28.5282327 C25.141296,28.0114135 25.1746169,27.2068048 25.6914361,26.7310889 C26.2082554,26.255373 27.0128641,26.2886939 27.4885799,26.8055132 L31.682877,31.2051981 L39.9953162,23.3416122 C40.3298457,23.033878 40.803138,22.9279893 41.2369084,23.0638333 C41.6706789,23.1996772 41.9990274,23.556616 42.0982682,24.0001942 C42.1975091,24.4437724 42.0525653,24.9066 41.7180357,25.2143342 L41.7180357,25.1762566 L41.7180357,25.1762566 Z"
                id="path-2-shield-check"
            ></path>
            <filter
                x="-5.7%"
                y="-5.0%"
                width="122.6%"
                height="120.0%"
                filterUnits="objectBoundingBox"
                id="filter-3-shield-check"
            >
                <feOffset
                    dx="2"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="0.5"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_login"
                transform="translate(-730.000000, -656.000000)"
                fill-rule="nonzero"
            >
                <g
                    id="icon_login_verification"
                    transform="translate(710.000000, 643.000000)"
                >
                    <use
                        fill="black"
                        fill-opacity="1"
                        filter="url(#filter-3-shield-check)"
                        xlink:href="#path-2-shield-check"
                    ></use>
                    <use
                        fill="url(#linearGradient-1-shield-check)"
                        xlink:href="#path-2-shield-check"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
</template>
