<template>
    <svg
        width="33px"
        height="33px"
        viewBox="0 0 33 33"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_login_user</title>
        <defs>
            <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="linearGradient-user"
            >
                <stop stop-color="#F3D68F" offset="0%"></stop>
                <stop stop-color="#A18240" offset="100%"></stop>
            </linearGradient>
            <path
                d="M24.4285714,21.5714286 C24.4285714,26.305 28.2664286,30.1428571 33,30.1428571 C37.7335714,30.1428571 41.5714286,26.305 41.5714286,21.5714286 C41.5714286,16.8378571 37.7335714,13 33,13 C28.2664286,13 24.4285714,16.8378571 24.4285714,21.5714286 L24.4285714,21.5714286 L24.4285714,21.5714286 Z M26.5714286,32.2857143 C21.8378571,32.2857143 18,36.1235714 18,40.8571429 L18,40.8571429 C18,42.0406096 18.9641759,43 20.133476,43 L45.866524,43 C47.0448103,43 48,42.0413875 48,40.8571429 L48,40.8571429 C48,36.1235714 44.1621429,32.2857143 39.4285714,32.2857143 L26.5714286,32.2857143 L26.5714286,32.2857143 Z"
                id="path-user"
            ></path>
            <filter
                x="-5.0%"
                y="-5.0%"
                width="120.0%"
                height="120.0%"
                filterUnits="objectBoundingBox"
                id="filter-user"
            >
                <feOffset
                    dx="2"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="0.5"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_login"
                transform="translate(-728.000000, -308.000000)"
                fill-rule="nonzero"
            >
                <g
                    id="icon_login_user"
                    transform="translate(710.000000, 295.000000)"
                >
                    <use
                        fill="black"
                        fill-opacity="1"
                        filter="url(#filter-user)"
                        xlink:href="#path-user"
                    ></use>
                    <use
                        fill="url(#linearGradient-user)"
                        xlink:href="#path-user"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
</template>

