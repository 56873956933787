
import {
    defineComponent,
    onBeforeUnmount,
    PropType,
    TableData,
    watch
} from 'vue'
import RoadMap from '@/components/roadmap/index.vue'
import { useListItem } from '@/composables/useListitem'

export default defineComponent({
    props: {
        activeDesk: {
            required: true,
            type: Number as PropType<number>
        },
        mData: {
            required: true,
            type: Object as PropType<TableData>
        },
        displayType: {
            default: 0,
            type: Number as PropType<number>
        }
    },
    components: {
        'road-map': RoadMap
    },
    setup(props) {
        let countDownInterval: boolean | number = false
        const {
            shoeRound,
            tableName,
            currentCount,
            timerColor,
            redPairCount,
            bluePairCount,
            greenCount,
            blueCount,
            redCount,
            limits,
            isAtThisTable,
            enterToDesk,
            onRoadMapDataParsed,
            t
        } = useListItem(props)

        onBeforeUnmount(() => {
            stopCountDown()
        })

        const startCountDown = () => {
            if (typeof countDownInterval !== 'number') {
                currentCount.value = props.mData.expand.daoJiShiBetTime

                countDownInterval = setInterval(() => {
                    if (currentCount.value < 0) {
                        stopCountDown()
                    } else {
                        currentCount.value -= 1
                    }
                }, 1000)
            }
        }

        const stopCountDown = (): void => {
            if (typeof countDownInterval === 'number') {
                clearInterval(countDownInterval)
                countDownInterval = false
                currentCount.value = -1
            }
        }

        watch(
            () => props.mData,
            (_value) => {
                if (_value.expand.daoJiShiBetTime > 0) {
                    startCountDown()
                } else {
                    stopCountDown()
                }
            },
            { immediate: true }
        )

        return {
            shoeRound,
            tableName,
            currentCount,
            timerColor,
            redPairCount,
            bluePairCount,
            greenCount,
            blueCount,
            redCount,
            limits,
            isAtThisTable,
            enterToDesk,
            onRoadMapDataParsed,
            t
        }
    }
})
