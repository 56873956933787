<template>
    <svg
        width="35px"
        height="35px"
        viewBox="0 0 35 35"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_lobby_fullscreen</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_lobby"
                transform="translate(-1690.000000, -18.000000)"
                fill="#CDB170"
                fill-rule="nonzero"
            >
                <path
                    d="M1722.08333,18 L1717.70833,18 L1717.70833,20.9166667 L1722.08333,20.9166667 L1722.08333,25.2916667 L1725,25.2916667 L1725,18 L1722.08333,18 Z M1722.08333,50.0833333 L1717.70833,50.0833333 L1717.70833,53 L1725,53 L1725,45.7083333 L1722.08333,45.7083333 L1722.08333,50.0833333 Z M1692.91667,20.9166667 L1697.29167,20.9166667 L1697.29167,18 L1690,18 L1690,25.2916667 L1692.91667,25.2916667 L1692.91667,20.9166667 Z M1692.91667,45.7083333 L1690,45.7083333 L1690,53 L1697.29167,53 L1697.29167,50.0833333 L1692.91667,50.0833333 L1692.91667,45.7083333 Z M1719.16667,42.7916667 L1719.16667,28.2083333 C1719.16667,25.7922195 1717.20778,23.8333333 1714.79167,23.8333333 L1700.20833,23.8333333 C1697.79222,23.8333333 1695.83333,25.7922195 1695.83333,28.2083333 L1695.83333,42.7916667 C1695.83333,45.2077805 1697.79222,47.1666667 1700.20833,47.1666667 L1714.79167,47.1666667 C1717.20778,47.1666667 1719.16667,45.2077805 1719.16667,42.7916667 Z"
                    id="icon_lobby_fullscreen"
                ></path>
            </g>
        </g>
    </svg>
</template>

