
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    SocketMessage,
    TableData,
    UserInfo,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import Dropdown2 from '@/components/drop-down-2.vue'
import CountdownTimer from '@/components/timer-count-down.vue'
import { useListItem } from '@/composables/useListitem'
import { RoadMapData } from './roadmap/datamodel/RoadMapData'
import { parseRoadMap } from './roadmap/utils'
import DPrediction1 from '@/components/d-prediction-1.vue'
import Roadmap from '@/components/roadmap/index.vue'
import { Desk } from '@/types/Desk'
import { RoadMapSummaryData } from './roadmap/datamodel/types'
import BetGroup from '@/components/ctrl-bet-box-group-multi.vue'
import { EventBuss, SOCKET_MESSAGE } from '@/types/global'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'

export default defineComponent({
    emits: ['select-table', 'remove', 'stop-betting'],
    props: {
        position: {
            type: Number as PropType<number>,
            required: true
        },
        desk: {
            type: Object as PropType<TableData>,
            required: false
        }
    },
    components: {
        'drop-down-2': Dropdown2,
        'count-down-timer': CountdownTimer,
        'd-prediction-1': DPrediction1,
        'roadmap': Roadmap,
        'bet-group': BetGroup
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const router = useRouter()
        const store = useStore()
        const { t } = useI18n()
        let countDownInterval: boolean | number = false
        const currentCount = ref<number>(-1)
        const roadMapData = ref<RoadMapData>(parseRoadMap([]))
        const nextRedRoadMapSummaryData = ref<RoadMapSummaryData>({})
        const nextBlueRoadMapSummaryData = ref<RoadMapSummaryData>({})
        const roadmapview12 = ref<typeof Roadmap | undefined>(undefined)
        // eslint-disable-next-line no-undef
        const nodePlayer = ref<NodePlayer | undefined>(undefined)
        let videoPlayerCollection: Array<any> = new Array<any>()

        const videoRef1 = ref<HTMLElement | undefined>()
        const videoRef2 = ref<HTMLElement | undefined>()

        const hasTempBet = ref(false)

        const refBetGroup = ref<any>()

        const videoIsLoading = ref(true)
        const mapKey = ref(new Date().getTime())
        const showMessageBox = ref(false)

        onMounted(() => {
            emitter.on(EventBuss.WINDOW_RESIZED, refreshRoamapViewKey)
            emitter.on(EventBuss.MULTI_CANCEL_GAME, handleCancelGame)
            emitter.on(EventBuss.MULTI_END_GAME, handleCancelGame)
            // emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)

            // if (props.desk) {
            //     console.log(props.desk)
            // }

            // if (refBetGroup.value) refBetGroup.value.resetBetUI()
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.WINDOW_RESIZED, refreshRoamapViewKey)
            emitter.off(EventBuss.MULTI_CANCEL_GAME, handleCancelGame)
            emitter.off(EventBuss.MULTI_END_GAME, handleCancelGame)
            // emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
            stopCountDown()
            disposeVideoPlayer()
        })

        const handleCancelGame = (_deskId: number) => {
            if (props.desk && props.desk.id === _deskId && refBetGroup.value) {
                refBetGroup.value.endGame()
            }
        }

        const refreshRoamapViewKey = () => {
            mapKey.value = new Date().getTime()
        }

        const startCountDown = () => {
            if (typeof countDownInterval !== 'number') {
                currentCount.value = props.desk!.expand.daoJiShiBetTime

                countDownInterval = setInterval(() => {
                    if (currentCount.value < 0) {
                        stopCountDown()
                    } else {
                        currentCount.value -= 1
                    }
                }, 1000)
            }
        }

        const stopCountDown = (): void => {
            if (typeof countDownInterval === 'number') {
                clearInterval(countDownInterval)
                countDownInterval = false
                currentCount.value = -1
                if (props.desk) emit('stop-betting', props.desk.id)

                if (refBetGroup.value) {
                    refBetGroup.value.resetTempBet()
                }
            }
        }

        const predict = (preType: number) => {
            if (roadmapview12.value) {
                roadmapview12.value.Predict(preType)
            }
        }

        const onRoadMapDataParsed = (
            a: RoadMapData,
            b: RoadMapData,
            c: RoadMapData
        ) => {
            roadMapData.value = a
            nextRedRoadMapSummaryData.value = { data: b }
            nextBlueRoadMapSummaryData.value = { data: c }
        }

        const onPlaceTempBetOrClear = (_hasTempAndConfirmBet: boolean) => {
            hasTempBet.value = _hasTempAndConfirmBet
            // console.log('hsdfhe' + _hasTempAndConfirmBet)
        }

        const confirmTempBet = () => {
            if (refBetGroup.value) refBetGroup.value.confirmTempBet()
        }

        const cancelTempbet = () => {
            if (refBetGroup.value) refBetGroup.value.resetTempBet()
        }

        const enterTable = () => {
            if (props.desk)
                router.push({
                    name: ROUTES.GAME,
                    query: { desk: props.desk.id }
                })
        }

        // region Video Player Helpers
        const initVideoPlayer = () => {
            videoIsLoading.value = true
            disposeVideoPlayer()

            // console.log

            if (!videoRef1.value || !videoRef2.value) return

            const videoPaths = [
                process.env.VIDEO1,
                process.env.VIDEO2,
                process.env.VIDEO3
            ]
            let isVideoConnected = false

            const videoUrl = props.desk!.cmccLine || ''

            videoPaths.forEach((vPath, _vId) => {
                const _videoUrl =
                    vPath +
                    '/' +
                    videoUrl.replace(/(http:|)(^|\/\/)(.*?\/)/g, '')

                // eslint-disable-next-line no-undef
                let _player: any = new NodePlayer()

                _player.setView(videoRef1.value!.id)
                _player.setVolume(0)
                _player.setBufferTime(1000)
                _player.on('start', () => {
                    if (!isVideoConnected) {
                        isVideoConnected = true

                        nodePlayer.value = _player

                        nodePlayer.value!.clearView()
                        nodePlayer.value!.setView(videoRef2.value!.id)
                        nodePlayer.value!.on(
                            'buffer',
                            (evt: 'empty' | 'buffering' | 'full') => {
                                if (evt === 'empty' || evt === 'buffering') {
                                    videoIsLoading.value = true
                                } else if (evt === 'full') {
                                    videoIsLoading.value = false
                                }
                            }
                        )
                        nodePlayer.value!.on('error', () => {
                            videoIsLoading.value = true
                            setTimeout(() => {
                                nodePlayer.value!.clearView()
                                nodePlayer.value!.stop()
                                initVideoPlayer()
                            }, 1000)
                        })
                    } else {
                        _player.stop()
                        _player.clearView()
                        _player = undefined
                    }
                })

                _player['vId'] = String(_vId)
                videoPlayerCollection.push(_player)
                _player.start(_videoUrl)
            })
        }

        const disposeVideoPlayer = () => {
            if (nodePlayer.value) {
                nodePlayer.value.stop()
                nodePlayer.value.clearView()
                nodePlayer.value = undefined
                videoIsLoading.value = true
            }

            // eslint-disable-next-line no-undef
            videoPlayerCollection.forEach((v: NodePlayer) => {
                v.clearView()
                v.stop()
            })

            videoPlayerCollection = []
        }

        const kickOutFromTable = () => {
            showMessageBox.value = false

            emit('remove', props.position)
        }

        const handleSocketMessage = (_message: SocketMessage | any) => {
            const { type } = _message
            const _type = type.toUpperCase()

            if (_type === SOCKET_MESSAGE.TABLE_VISIT_STATUS) {
                if (
                    props.desk &&
                    props.desk.reserverd === 1 &&
                    userInfo.value
                ) {
                    if (props.desk.id === _message.tableId) {
                        const visitCodes: string[] =
                            _message.visitCode.split(',')
                        if (
                            _message.visitCode === '' ||
                            visitCodes.indexOf(userInfo.value.code) === -1
                        ) {
                            showMessageBox.value = true
                        }
                    }
                }
            }
        }

        // endregion

        const tableName = computed(() => {
            if (props.desk) {
                const { tableNo, commandType } = props.desk

                let cmd = ''
                if (commandType === 0) cmd = '通用台'
                else if (commandType === 1) cmd = '电投台'
                else if (commandType === 2) cmd = '指令台'

                if (props.desk.gameTypeNo.toUpperCase() === 'G002')
                    return `${t('baccarattable')}${tableNo}`
                // return `${t('baccarattable')}${tableNo}(${cmd})`
                else return `${t('dragontigertable')}${tableNo}`
                //return `${t('dragontigertable')}${tableNo}(${cmd})`
            }

            return ''
        })

        const gameNo = computed(() => {
            if (props.desk) {
                return props.desk.expand.gameNo
            }

            return ''
        })

        const showAndJuNo = computed(() => {
            if (props.desk) {
                const { shoeNo, juNo } = props.desk.expand
                return `${shoeNo}靴${juNo}局`
            }

            return '0靴0局'
        })

        const redLimit = computed(() => {
            if (props.desk) {
                return `${props.desk.downLimit}-${props.desk.upLimit}`
            }
            return '0-0'
        })

        const redCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.redCount
            return 0
        })

        const blueCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.blueCount
            return 0
        })

        const greenCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.tieCount
            return 0
        })

        const redPairCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.redPairCount
            return 0
        })

        const bluePairCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.bluePairCount
            return 0
        })

        const gameType = computed(() => {
            if (props.desk) {
                return props.desk.gameTypeNo === 'G002' ? 1 : 2
            }

            return 1
        })

        const limitData = computed((): string[] => {
            if (props.desk) {
                let list = []
                const {
                    downLimit,
                    upLimit,
                    pairDownLimit,
                    pairUpLimit,
                    tieDownLimit,
                    tieUpLimit
                } = props.desk

                if (gameType.value === 1) {
                    list.push(`庄闲:${downLimit}-${upLimit}`)
                    list.push(`和:${tieDownLimit}-${tieUpLimit}`)
                    list.push(`对子:${pairDownLimit}-${pairUpLimit}`)
                    return list
                } else {
                    list.push(`龙虎:${downLimit}-${upLimit}`)
                    list.push(`和:${tieDownLimit}-${tieUpLimit}`)
                    return list
                }
            }
            return []
        })

        const mapData = computed(() => {
            if (props.desk) return props.desk.expand.resultList
            return []
        })

        const userInfo = computed((): UserInfo => store.getters['userInfo'])

        watch(
            () => props.desk,
            (_value, _oldvalue) => {
                console.log('APPP')
                if (_value) {
                    if (_value.expand.daoJiShiBetTime > 0) {
                        startCountDown()
                    } else {
                        stopCountDown()
                    }

                    if (!_oldvalue) {
                        setTimeout(() => {
                            initVideoPlayer()
                        }, 1500)
                    } else {
                        if (_value.tableNo !== _oldvalue?.tableNo) {
                            setTimeout(() => {
                                initVideoPlayer()
                            }, 1500)
                        }

                        if (_value.status !== _oldvalue.status) {
                            if (refBetGroup.value) {
                                refBetGroup.value.handleNotifyStatus(
                                    _value.status
                                )
                            }
                        }
                    }
                } else {
                    disposeVideoPlayer()
                    stopCountDown()
                    console.log('dispose video and timer')
                }
            },
            { immediate: true }
        )

        watch(
            () => props.desk,
            (_value) => {
                console.log('chage')
                if (_value) {
                    if (props.desk && _value.reserverd === 1) {
                        const visitCodes = _value.visitCode?.split(',') || []
                        if (visitCodes.indexOf(userInfo.value.code) === -1) {
                            showMessageBox.value = true
                        } else {
                            showMessageBox.value = false
                        }
                    } else {
                        showMessageBox.value = false
                    }
                } else {
                    showMessageBox.value = false
                }
            },
            { deep: true, immediate: true }
        )

        return {
            tableName,
            showAndJuNo,
            redLimit,
            gameNo,
            redCount,
            blueCount,
            greenCount,
            redPairCount,
            bluePairCount,
            gameType,
            mapData,
            nextRedRoadMapSummaryData,
            nextBlueRoadMapSummaryData,
            roadmapview12,
            currentCount,
            videoRef1,
            videoRef2,
            mapKey,
            refBetGroup,
            hasTempBet,
            videoIsLoading,
            limitData,
            showMessageBox,
            kickOutFromTable,
            confirmTempBet,
            cancelTempbet,
            predict,
            onRoadMapDataParsed,
            onPlaceTempBetOrClear,
            enterTable
        }
    }
})
