
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { RoadMapData } from './roadmap/datamodel/RoadMapData'
import { RoadMapSummaryData } from './roadmap/datamodel/types'

export default defineComponent({
    props: {
        color: {
            required: true,
            type: Number as PropType<number>
        },
        mapdata: {
            required: true,
            type: Object as PropType<RoadMapSummaryData>
        },
        gameType: {
            required: true,
            type: Number as PropType<number>
        },
        display: {
            required: false,
            type: String as PropType<'horizontal' | 'vertical'>,
            default: 'horizontal'
        }
    },
    setup(props, context) {
        const { t } = useI18n()
        const preColor = computed(() => {
            if (props.color === 1 || props.color === 3) return 'red'
            else if (props.color === 2 || props.color === 4) return 'blue'
            return ''
        })

        const preLabel = computed(() => {
            let _label = ''
            if (props.color === 1 && props.gameType === 1) {
                _label = `${t('bankerabt')}${t('preask')}`
            } else if (props.color === 1 && props.gameType === 2) {
                _label = `${t('dragonabt')}${t('preask')}`
            } else if (props.color === 2 && props.gameType === 1) {
                _label = `${t('playerabt')}${t('preask')}`
            } else if (props.color === 2 && props.gameType === 2) {
                _label = `${t('tigerabt')}${t('preask')}`
            }

            return _label
        })

        const hasNoData = computed(() => {
            if (!props.mapdata.data) return true

            const mapData = props.mapdata.data as RoadMapData

            return mapData.bigeyes.length <= 0
        })

        const hasSmall = computed(() => {
            if (!props.mapdata.data) return true

            const mapData = props.mapdata.data as RoadMapData

            return mapData.smalls.length <= 0
        })

        const hasCockroach = computed(() => {
            if (!props.mapdata.data) return true

            const mapData = props.mapdata.data as RoadMapData

            return mapData.cockroachs.length <= 0
        })

        const bigeyeColor = computed(() => {
            if (!props.mapdata.data) return ''
            const mapData = props.mapdata.data as RoadMapData

            if (mapData.bigeyes.length <= 0) return ''

            return mapData.bigeyes[mapData.bigeyes.length - 1].type === 0
                ? 'red'
                : 'blue'
        })

        const smallColor = computed(() => {
            if (!props.mapdata.data) return ''
            const mapData = props.mapdata.data as RoadMapData

            if (mapData.smalls.length <= 0) return ''

            return mapData.smalls[mapData.smalls.length - 1].type === 0
                ? 'red'
                : 'blue'
        })

        const cocroachColor = computed(() => {
            if (!props.mapdata.data) return ''
            const mapData = props.mapdata.data as RoadMapData

            if (mapData.cockroachs.length <= 0) return ''

            return mapData.cockroachs[mapData.cockroachs.length - 1].type === 0
                ? 'red'
                : 'blue'
        })

        return {
            hasNoData,
            hasSmall,
            hasCockroach,
            bigeyeColor,
            smallColor,
            cocroachColor,
            preColor,
            preLabel
        }
    }
})
