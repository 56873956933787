
import {
    computed,
    defineComponent,
    Emitter,
    GameOption,
    HallData,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    ResponseData,
    ResultMemberList,
    SocketMessage,
    TableData,
    UserInfo,
    watch
} from 'vue'
import ListItem from '@/components/loby-item.vue'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss, HALL, SOCKET_MESSAGE } from '@/types/global'
import { getHallData, getMultiTableDetail } from '@/api'
import { useRoute, useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { useI18n } from 'vue-i18n'
import { VTextMarquee } from 'vue-text-marquee'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import CtrlChips from '@/components/ctrl-chips.vue'
import {
    IUserL,
    IBalance,
    IOneBar,
    ITwoBar,
    IFull,
    IArrowH,
    INormal,
    IBalanceG
} from '@/components/icons'
import CtrlDropdown from '@/components/drop-down.vue'
import screenfull from 'screenfull'
import MultitableItem from '@/components/multi-table-item.vue'
export default defineComponent({
    props: {
        hall: {
            type: String as PropType<string>
        }
    },
    components: {
        'list-item': ListItem,
        'marquee-text': VTextMarquee,
        'multi-table-item': MultitableItem,
        CtrlChips,
        IUserL,
        IBalance,
        IOneBar,
        ITwoBar,
        IFull,
        CtrlDropdown,
        IArrowH,
        INormal,
        IBalanceG
    },
    setup(props) {
        const emitter = inject('emitter') as Emitter
        const router = useRouter()
        const route = useRoute()
        const store = useStore()
        const { t } = useI18n()
        const tabId = ref<number>(0)
        const desks = ref<Array<TableData>>([])
        const multiDesks = ref<Array<TableData>>([])
        const listKey = ref<number>(new Date().getTime())
        const noticeKey = ref<number>(new Date().getTime())
        const lobyNotice = ref<string>('')
        const currentDeskNo = ref<number>(-1)

        const limitOption = ref<number>(0)
        const gameOption = ref<number>(0)
        const listDisplayType = ref<number>(0)
        const isMultitbableView = ref(false)

        const memberid = computed(() => store.getters['memberid'])

        let loadDataFailedCounter = 0

        const gameOptionArray = ref<GameOption[]>([
            {
                typeName: '全部游戏',
                dealType: -1,
                fastType: -1,
                gameType: ''
            },
            {
                typeName: '极速百家乐',
                dealType: 2,
                fastType: 1,
                gameType: 'G002'
            },
            {
                typeName: '先发牌百家乐',
                dealType: 1,
                fastType: -1,
                gameType: 'G002'
            },
            {
                typeName: '传统百家乐',
                dealType: 2,
                fastType: -1,
                gameType: 'G002'
            },
            {
                typeName: '传统龙虎',
                dealType: 2,
                fastType: -1,
                gameType: 'G003'
            },
            {
                typeName: '先发牌龙虎',
                dealType: 1,
                fastType: -1,
                gameType: 'G003'
            },
            {
                typeName: '极速龙虎',
                dealType: 2,
                fastType: 1,
                gameType: 'G003'
            }
        ])

        const limitOptionArray = ref<string[]>([])
        const multiTableSelected = ref([0, 0, 0, 0])
        let failureCounter = 0

        //#region  Vue Helpers
        onMounted(() => {
            // assign query to tabId
            if (props.hall) {
                const { hall } = props
                tabId.value = HALL.convert(hall)
            }
            multiTableSelected.value = multitableDesks.value

            if (
                HALL.parse(tabId.value) === HALL.MULTI &&
                getMultitableIds() !== ''
            ) {
                isMultitbableView.value = true
                isMultitbableView.value = true
                loadMultiDesk()
            } else {
                loadData()
            }

            // desks.value = Desk.ParseList(fkData)
        })

        onBeforeMount(() => {
            // register events
            emitter.on(EventBuss.WINDOW_RESIZED, handleResizeWindow)
            emitter.on(EventBuss.SESSION_EXPIRED, handleOnExpiredSession)
            // listen to websocket events
            emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
        })

        onBeforeUnmount(() => {
            // unregister events
            emitter.off(EventBuss.WINDOW_RESIZED, handleResizeWindow)
            emitter.off(EventBuss.SESSION_EXPIRED, handleOnExpiredSession)
            emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
        })
        //#endregion

        const loadData = (_forceReload = false) => {
            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)

            const _params: any = {
                token: token.value,
                commandType: commandType.value
            }

            if (tabId.value === 1) {
                _params.gameTypeNo = 'G002'
            } else if (tabId.value === 2) {
                _params.gameTypeNo = 'G003'
            }

            if (limitOption.value !== 0) {
                _params.limitType = limitOptionArray.value[limitOption.value]
            }

            if (gameOption.value !== 0) {
                _params.dealType =
                    gameOptionArray.value[gameOption.value].dealType
                _params.fastType =
                    gameOptionArray.value[gameOption.value].fastType
                _params.gameTypeNo =
                    gameOptionArray.value[gameOption.value].gameType
            }

            getHallData(_params)
                .then((response) => {
                    loadDataFailedCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    const responseData = response.data as ResponseData<HallData>
                    if (responseData.status === 200) {
                        desks.value = responseData.data!.gameTableList
                        store.commit(
                            AppMutation.SET_USER_GUNNER_BIND,
                            responseData.data?.bindGunner || false
                        )

                        if (
                            isFreeUser.value &&
                            visitorTable.value.length === 0
                        ) {
                            let _tempDesk = [...desks.value]
                            const _visitorDeskNum: number[] = []

                            for (
                                let _deskIndex = 0;
                                _deskIndex < desks.value.length;
                                _deskIndex++
                            ) {
                                if (_visitorDeskNum.length === 3) {
                                    break
                                } else {
                                    const _desk =
                                        _tempDesk[
                                            Math.floor(
                                                Math.random() * _tempDesk.length
                                            )
                                        ]
                                    if (_desk) {
                                        _tempDesk.splice(
                                            _tempDesk.indexOf(_desk),
                                            1
                                        )
                                        _visitorDeskNum.push(_desk.id)
                                    }
                                }
                            }
                            store.commit(
                                AppMutation.SET_VISITOR_DESK,
                                _visitorDeskNum
                            )
                        }

                        if (limitOptionArray.value.length === 0)
                            limitOptionArray.value = [
                                '全部限红',
                                ...responseData!.data!.limitList
                            ]

                        let _lobyNoticeList = responseData.data!.noticeList
                        let _lobyNotice = ''

                        _lobyNoticeList.forEach((_notice: any, index) => {
                            if (index === 0) _lobyNotice = _notice.content
                            else
                                _lobyNotice = `${_lobyNotice},   ${_notice.content}`
                        })

                        lobyNotice.value = _lobyNotice
                        noticeKey.value = new Date().getTime()
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch((e) => {
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,
                            '当前网络不佳，请重新尝试'
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        setTimeout(() => {
                            loadData(true)
                        }, 1500)
                    }
                })
        }

        const handleSocketMessage = (_message: SocketMessage | any) => {
            const { type } = _message
            const _type = type.toUpperCase()

            // console.log(_type)
            if (
                _type === SOCKET_MESSAGE.NEW_GAME ||
                _type === SOCKET_MESSAGE.END_GAME ||
                _type === SOCKET_MESSAGE.STOP_GAME
            ) {
                const _desk = desks.value.find(
                    (_d) => _d.id === _message.gameTable!.id
                )
                if (_desk && _type !== SOCKET_MESSAGE.CANCEL_GAME) {
                    const _index = desks.value.indexOf(_desk)
                    if (_index !== -1) desks.value[_index] = _message.gameTable!
                }
                if (tabId.value === 3 && isMultitbableView) {
                    const _table = multiDesks.value.find(
                        (_d) => _d.id === _message.gameTable!.id
                    )
                    if (_table) {
                        const _tableIndex = multiDesks.value.indexOf(_table)
                        if (_tableIndex !== -1) {
                            const sDesk = _message.gameTable
                            if (_type === SOCKET_MESSAGE.NEW_GAME) {
                                const _copyDesk = {
                                    ...multiDesks.value[_tableIndex]
                                }

                                _copyDesk.expand.shoeNo = sDesk.expand.shoeNo
                                _copyDesk.expand.juNo = sDesk.expand.juNo
                                _copyDesk.expand.gameNo = sDesk.expand.gameNo
                                _copyDesk.expand.daoJiShiBetTime =
                                    sDesk.expand.daoJiShiBetTime

                                // reset all data
                                _copyDesk.expand.betFormList = []
                                _copyDesk.expand.xianAmount = 0
                                _copyDesk.expand.xianDuiAmount = 0
                                _copyDesk.expand.zhuangAmount = 0
                                _copyDesk.expand.zhuangDuiAmount = 0
                                _copyDesk.expand.heAmount = 0
                                // reset card result
                                _copyDesk.expand.playerOne = undefined
                                _copyDesk.expand.playerTwo = undefined
                                _copyDesk.expand.playerThree = undefined
                                _copyDesk.expand.bankerOne = undefined
                                _copyDesk.expand.bankerTwo = undefined
                                _copyDesk.expand.bankerThree = undefined
                                _copyDesk.expand.playerCount = undefined
                                _copyDesk.expand.bankerCount = undefined
                                _copyDesk.expand.whoWin = -1

                                _copyDesk.status = 1

                                multiDesks.value[_tableIndex] = _copyDesk
                            } else if (_type === SOCKET_MESSAGE.STOP_GAME) {
                                const _copyDesk = {
                                    ...multiDesks.value[_tableIndex]
                                }

                                _copyDesk.expand.daoJiShiBetTime = 0
                                _copyDesk.status = 2
                                multiDesks.value[_tableIndex] = _copyDesk
                            } else if (_type === SOCKET_MESSAGE.END_GAME) {
                                const _copyDesk = {
                                    ...multiDesks.value[_tableIndex]
                                }
                                _copyDesk.expand.playerOne =
                                    sDesk.expand.playerOne
                                _copyDesk.expand.playerTwo =
                                    sDesk.expand.playerTwo
                                _copyDesk.expand.playerThree =
                                    sDesk.expand.playerThree
                                _copyDesk.expand.bankerOne =
                                    sDesk.expand.bankerOne
                                _copyDesk.expand.bankerTwo =
                                    sDesk.expand.bankerTwo
                                _copyDesk.expand.bankerThree =
                                    sDesk.expand.bankerThree
                                _copyDesk.expand.playerCount =
                                    sDesk.expand.playerCount
                                _copyDesk.expand.bankerCount =
                                    sDesk.expand.bankerCount
                                _copyDesk.expand.whoWin = sDesk.expand.whoWin
                                _copyDesk.expand.resultList =
                                    sDesk.expand.resultList
                                _copyDesk.expand.gameRecordList =
                                    sDesk.expand.gameRecordList

                                // reset bet data
                                _copyDesk.expand.betFormList = []
                                _copyDesk.expand.xianAmount = 0
                                _copyDesk.expand.xianDuiAmount = 0
                                _copyDesk.expand.zhuangAmount = 0
                                _copyDesk.expand.zhuangDuiAmount = 0
                                _copyDesk.expand.heAmount = 0

                                const memberResultList = sDesk.expand
                                    .resultMemberList as ResultMemberList[]
                                if (
                                    memberResultList &&
                                    memberResultList.length > 0
                                ) {
                                    const _memberResultInfo =
                                        memberResultList.find(
                                            (x) => x.memberId === memberid.value
                                        )

                                    if (_memberResultInfo) {
                                        store.commit(
                                            AppMutation.SET_COIN,
                                            _memberResultInfo.amount
                                        )

                                        if (_memberResultInfo.winResult !== 0) {
                                            let _amtStr = '0'

                                            if (_memberResultInfo.winResult > 0)
                                                _amtStr = `+${_memberResultInfo.winResult}`
                                            else
                                                _amtStr = String(
                                                    _memberResultInfo.winResult
                                                )

                                            emitter.emit(
                                                EventBuss.SMALL_TOAST,
                                                {
                                                    msg: _amtStr,
                                                    id: _copyDesk.id
                                                }
                                            )
                                        }
                                        emitter.emit(
                                            EventBuss.MULTI_END_GAME,
                                            _copyDesk.id
                                        )
                                    }
                                }

                                multiDesks.value[_tableIndex] = _copyDesk
                            }
                        }
                    }
                }
                // console.log(_message)
            } else if (_type === SOCKET_MESSAGE.CANCEL_GAME) {
                if (tabId.value === 3 && isMultitbableView) {
                    const _table = multiDesks.value.find(
                        (_d) => _d.id === _message.gameTable!.id
                    )

                    if (_table) {
                        const _tableIndex = multiDesks.value.indexOf(_table)

                        if (_tableIndex !== -1) {
                            const sDesk = _message.gameTable
                            const _copyDesk = {
                                ...multiDesks.value[_tableIndex]
                            }
                            _copyDesk.expand.daoJiShiBetTime = -1
                            _copyDesk.expand.betFormList = []
                            _copyDesk.expand.xianAmount = 0
                            _copyDesk.expand.xianDuiAmount = 0
                            _copyDesk.expand.zhuangAmount = 0
                            _copyDesk.expand.zhuangDuiAmount = 0
                            _copyDesk.expand.heAmount = 0

                            const memberResultList = sDesk.expand
                                .resultMemberList as ResultMemberList[]

                            if (
                                memberResultList &&
                                memberResultList.length > 0
                            ) {
                                const _memberResultInfo = memberResultList.find(
                                    (x) => x.memberId === memberid.value
                                )

                                if (_memberResultInfo) {
                                    store.commit(
                                        AppMutation.SET_COIN,
                                        _memberResultInfo.amount
                                    )

                                    setTimeout(() => {
                                        emitter.emit(
                                            EventBuss.MULTI_CANCEL_GAME,
                                            _copyDesk.id
                                        )
                                    }, 250)
                                }
                            }

                            multiDesks.value[_tableIndex] = _copyDesk
                        }
                    }
                }
            } else if (_type === SOCKET_MESSAGE.GUNNER_VERIFY) {
                if (
                    _message.data.status === 5 &&
                    _message.data.verifyStatus === 1
                ) {
                    store.commit(AppMutation.SET_DESK, _message.data.tableId)
                } else if (
                    _message.data.status === 9 &&
                    _message.data.verifyStatus === 1
                ) {
                    store.commit(AppMutation.SET_USER_GUNNER_BIND, false)
                    emitter.emit(EventBuss.TOAST_MESSAGE, _message.data.content)
                } else if (
                    _message.data.status === 10 &&
                    _message.data.verifyStatus === 1
                ) {
                    store.commit(AppMutation.SET_USER_GUNNER_BIND, true)
                    emitter.emit(EventBuss.TOAST_MESSAGE, _message.data.content)
                } else if (
                    _message.data.status == 16 ||
                    _message.data.status === 17
                ) {
                    if (_message.data.verifyStatus === 1) {
                        // TODO: Check Reservation Status
                        const _table = desks.value.find(
                            (x) => x.id === _message.data.tableId
                        )

                        if (_table && !isMultitbableView.value) {
                            const _index = desks.value.indexOf(_table)

                            if (_index !== -1) {
                                if (
                                    _message.data!.isHavePeopleReserverd === 1
                                ) {
                                    desks.value[_index].reserverd = 1
                                    // TODO :
                                } else {
                                    desks.value[_index].reserverd = -1
                                }
                            }
                        } else {
                            const _table1 = multiDesks.value.find(
                                (x) => x.id === _message.data.tableId
                            )

                            if (_table1) {
                                const _index2 =
                                    multiDesks.value.indexOf(_table1)
                                if (
                                    _index2 !== -1 &&
                                    _message.data!.isHavePeopleReserverd === 1
                                ) {
                                    multiDesks.value[_index2].reserverd = 1
                                } else {
                                    multiDesks.value[_index2].reserverd = -1
                                }
                            }
                        }
                    }
                }
            } else if (_type === SOCKET_MESSAGE.BET) {
                if (tabId.value === 3 && isMultitbableView) {
                    const _table = multiDesks.value.find(
                        (_d) => _d.id === _message.gameTable!.id
                    )

                    if (_table && multiDesks.value.indexOf(_table) !== -1) {
                        const sDesk = _message.gameTable
                        const _tableIndex = multiDesks.value.indexOf(_table)

                        const _copyDesk = {
                            ...multiDesks.value[_tableIndex]
                        }

                        _copyDesk.expand.xianAmount = sDesk.expand.xianAmount
                        _copyDesk.expand.xianDuiAmount =
                            sDesk.expand.xianDuiAmount
                        _copyDesk.expand.zhuangAmount =
                            sDesk.expand.zhuangAmount
                        _copyDesk.expand.zhuangDuiAmount =
                            sDesk.expand.zhuangDuiAmount
                        _copyDesk.expand.heAmount = sDesk.expand.heAmount
                        if (sDesk.expand.memberId === memberid.value) {
                            _copyDesk.expand.betFormList =
                                sDesk.expand.betFormList
                            store.commit(
                                AppMutation.SET_COIN,
                                sDesk.expand.amount
                            )
                        }
                        multiDesks.value[_tableIndex] = _copyDesk
                    }
                }
            } else if (_type === SOCKET_MESSAGE.TABLE_VISIT_STATUS) {
                const _table = desks.value.find(
                    (x) => x.id === _message.tableId
                )
                if (_table && !isMultitbableView.value) {
                    const _index = desks.value.indexOf(_table)
                    if (_index !== -1) {
                        desks.value[_index].visitCode = _message.visitCode
                    }
                } else {
                    const _table1 = multiDesks.value.find(
                        (x) => x.id === _message.tableId
                    )

                    if (_table1) {
                        const _index2 = multiDesks.value.indexOf(_table1)
                        if (_index2 !== -1) {
                            multiDesks.value[_index2].visitCode =
                                _message.visitCode

                            console.log('chassssge')
                        }
                    }
                }
            }
        }

        const requestFullScreen = () => {
            emitter.emit(EventBuss.REQUEST_FULL_SCREEN)
        }

        const handleOnExpiredSession = (_hasMessage = true) => {
            if (_hasMessage) {
                emitter.emit(EventBuss.TOAST_MESSAGE, t('sessionexpired'))
            }
            store.commit(AppMutation.CLEAR_ALL)
            router.push({ name: ROUTES.LOGIN })
        }

        const handleResizeWindow = () => {
            listKey.value = new Date().getTime()
            noticeKey.value = new Date().getTime()
        }
        const changeDeskFilter = (_tabId: number) => {
            if (tabId.value === _tabId) return

            // desks.value = []

            tabId.value = _tabId
            router.push({ query: { hall: HALL.parse(_tabId) } })

            if (HALL.parse(tabId.value) === HALL.MULTI) {
                let _selected = 0
                multiTableSelected.value.forEach((_v) => {
                    if (_v !== 0) _selected++
                })
                if (_selected === 0) loadData()
                else {
                    isMultitbableView.value = true
                    loadMultiDesk()
                }
            } else {
                isMultitbableView.value = false
                loadData()
            }
        }

        const handleSelectDesk = (_tableNo: number, desk: TableData) => {
            if (HALL.parse(tabId.value) !== HALL.MULTI) {
                if (desk.reserverd === 1) {
                    if (
                        (userInfo.value && desk.visitCode) ||
                        desk.visitCode === ''
                    ) {
                        const visitCodes = desk.visitCode.split(',')
                        if (visitCodes.indexOf(userInfo.value.code) === -1) {
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                '该台桌已被包桌，请选择其他台桌进行投注'
                            )
                            return
                        }
                    }
                } else if (userInfo.value.memberType !== 1) {
                    if (
                        desk.commandType !== 0 &&
                        commandType.value !== desk.commandType
                    ) {
                        let _toastMessage = `${desk.tableNo}`

                        if (desk.commandType === 1) {
                            _toastMessage +=
                                '号台桌为电投台,请选择其他台桌游戏！'
                        } else {
                            _toastMessage +=
                                '号台桌为指令台,请选择其他台桌游戏！'
                        }
                        emitter.emit(EventBuss.TOAST_MESSAGE, _toastMessage)

                        return
                    }

                    if (
                        userInfo.value.commandType === 1 &&
                        !isBindToGunner.value
                    ) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            '您未绑定枪手，请先绑定枪手再进入台桌'
                        )
                        return
                    }
                }

                router.push({
                    name: ROUTES.GAME,
                    query: { desk: _tableNo }
                })
            } else {
                let _position = multiTableSelected.value.indexOf(_tableNo)
                if (_position === -1) {
                    let availablePosition = -1
                    for (let i = 0; i < multiTableSelected.value.length; i++) {
                        if (multiTableSelected.value[i] === 0) {
                            availablePosition = i
                            break
                        }
                    }
                    if (availablePosition === -1) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            '抱歉，您只能选择 4 个表'
                        )
                        return
                    }

                    multiTableSelected.value[availablePosition] = _tableNo
                } else {
                    multiTableSelected.value[_position] = 0
                }
            }
        }

        const stopBetMultitable = (id: number) => {
            const _desk = multiDesks.value.find((_d) => _d.id === id)

            if (_desk) {
                _desk.expand.daoJiShiBetTime = -1
                _desk.status = 2
            }
        }

        const showSettingDialog = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'setting'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const multiTableSelectTip = () => {
            let _selected = 0

            multitableDesks.value.forEach((_id) => {
                if (_id !== 0) _selected++
            })

            return `选择最多4张台进行投注 ${_selected}/4`
        }

        const gotoMultitableView = () => {
            store.commit(AppMutation.SET_MULTI_TABLE, multitableDesks.value)
            isMultitbableView.value = true
            loadMultiDesk()
        }

        const loadMultiDesk = (_forceReload = false) => {
            const _selectedTables = getMultitableIds()

            if (_selectedTables === '') return

            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)

            const _params: any = {
                token: token.value,
                memberId: userInfo.value.id,
                tableIds: _selectedTables
            }
            getMultiTableDetail(_params)
                .then((response) => {
                    loadDataFailedCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    const responseData = response.data as ResponseData<HallData>
                    if (responseData.status === 200) {
                        const _data = responseData.data!.gameTableList

                        if (_data) {
                            multiDesks.value = _data
                        }
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch((e) => {
                    console.log(e)
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,
                            '当前网络不佳，请重新尝试'
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        setTimeout(() => {
                            loadMultiDesk(true)
                        }, 1500)
                    }
                })
        }

        const getMultitableIds = () => {
            let tableIds = ''

            multitableDesks.value.forEach((_id, _index) => {
                if (_id !== 0) tableIds += `,${_id}`
            })

            if (tableIds.length > 0) tableIds = tableIds.substring(1)
            return tableIds
        }

        //#region Click Helper Methods
        const onChangeListDisplayType = (_display: number) => {
            if (_display === listDisplayType.value) return

            listDisplayType.value = _display
        }

        const removeFromMultitable = (position: number) => {
            const _multitableDesks = multitableDesks.value
            _multitableDesks[position] = 0
            store.commit(AppMutation.SET_MULTI_TABLE, _multitableDesks)
        }

        const showTipBox = () => {
            const _evt: MessageBoxEvent = {
                name: 'tipbox',
                type: MessageBoxActionType.open
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const selecMultitables = () => {
            isMultitbableView.value = false
            loadData()
        }

        //#endregion
        const user = computed(() => store.getters['user'])
        const token = computed(() => store.getters['token'])
        const coin = computed(() => store.getters['coin'])
        const notice = computed((): string => store.getters['lobyNotice'])
        const commandType = computed(() => store.getters['commandType'])
        const userInfo = computed((): UserInfo => store.getters['userInfo'])

        const isfullScreen = computed(() => store.getters['isfullScreen'])

        const fullScreenAvailable = computed(() => screenfull.isEnabled)
        const visitorTable = computed(
            (): number[] => store.getters['visitorTable']
        )
        const isFreeUser = computed(() => store.getters['isFreeUser'])

        const filteredDesk = computed((): Array<TableData> => {
            if (isFreeUser.value) {
                return desks.value.filter(
                    (_desk) => visitorTable.value.indexOf(_desk.id) !== -1
                )
            } else {
                if (tabId.value === 3) {
                    return desks.value.filter((_desk) => {
                        if (_desk.reserverd === 1 && userInfo.value) {
                            if (userInfo.value && _desk.visitCode) {
                                const visitCodes = _desk.visitCode.split(',')
                                if (
                                    visitCodes.indexOf(userInfo.value.code) !==
                                    -1
                                ) {
                                    return true
                                }
                            }
                            return false
                        } else {
                            return (
                                _desk.commandType === 2 ||
                                _desk.commandType === 0
                            )
                        }
                    })
                }

                return desks.value
            }
        })

        const multitableDesks = computed(
            (): number[] => store.getters['multitableDesks']
        )

        const isBindToGunner = computed(
            (): boolean => store.getters['isBindToGunner']
        )

        const desk1 = computed(() => {
            const tableId = multitableDesks.value[0]
            if (tableId === 0) return undefined

            const _desk = multiDesks.value.find(
                (_d) => _d.id === multitableDesks.value[0]
            )

            return _desk
        })

        const desk2 = computed(() => {
            const tableId = multitableDesks.value[1]
            if (tableId === 0) return undefined

            const _desk = multiDesks.value.find(
                (_d) => _d.id === multitableDesks.value[1]
            )

            return _desk
        })

        const desk3 = computed(() => {
            const tableId = multitableDesks.value[2]
            if (tableId === 0) return undefined

            const _desk = multiDesks.value.find(
                (_d) => _d.id === multitableDesks.value[2]
            )

            return _desk
        })

        const desk4 = computed(() => {
            const tableId = multitableDesks.value[3]
            if (tableId === 0) return undefined

            const _desk = multiDesks.value.find(
                (_d) => _d.id === multitableDesks.value[3]
            )

            return _desk
        })

        watch(
            () => [limitOption.value, gameOption.value],
            (o, n) => {
                loadData()
            }
        )

        return {
            isFreeUser,
            coin,
            tabId,
            desks,
            listKey,
            gameOptionArray,
            limitOptionArray,
            limitOption,
            gameOption,
            filteredDesk,
            user,
            currentDeskNo,
            notice,
            lobyNotice,
            noticeKey,
            isfullScreen,
            listDisplayType,
            fullScreenAvailable,
            multitableDesks,
            isMultitbableView,
            desk1,
            desk2,
            desk3,
            desk4,
            commandType,
            multiTableSelected,
            selecMultitables,
            multiTableSelectTip,
            changeDeskFilter,
            handleSelectDesk,
            showSettingDialog,
            requestFullScreen,
            onChangeListDisplayType,
            removeFromMultitable,
            gotoMultitableView,
            stopBetMultitable,
            showTipBox,
            getMultitableIds
        }
    }
})
