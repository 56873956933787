<template>
    <svg
        width="32px"
        height="31px"
        viewBox="0 0 32 31"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_login_layer</title>
        <defs>
            <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="linearGradient-layer"
            >
                <stop stop-color="#EFD28C" offset="0%"></stop>
                <stop stop-color="#A18341" offset="100%"></stop>
            </linearGradient>
            <path
                d="M20.2882475,33.3941421 L31.1293209,38.7846758 C31.7595911,39.0965141 32.4992489,39.0965141 33.129519,38.7846758 L43.9805934,33.3941421 C44.4646301,33.095304 45.0760522,33.0959524 45.5594539,33.3958165 C46.0428556,33.6956807 46.3150633,34.2431661 46.2623693,34.809575 C46.2096753,35.375984 45.8411461,35.8638605 45.3107251,36.069407 L34.4496497,41.4599407 C33.7280761,41.8160324 32.9340745,42.0008431 32.12942,41.9999971 C31.3247654,42.0008431 30.5307638,41.8160324 29.8091902,41.4599407 L18.9581158,36.069407 C18.4276948,35.8638605 18.0591656,35.375984 18.0064716,34.809575 C17.9537776,34.2431661 18.2259853,33.6956807 18.709387,33.3958165 C19.1927888,33.0959524 19.8042108,33.095304 20.2882475,33.3941421 Z M20.2882475,27.0535143 L31.1293209,32.444048 C31.7608207,32.7505832 32.4980193,32.7505832 33.129519,32.444048 L43.9805934,27.0535143 C44.4646301,26.7546762 45.0760522,26.7553246 45.5594539,27.0551887 C46.0428556,27.3550529 46.3150633,27.9025383 46.2623693,28.4689472 C46.2096753,29.0353562 45.8411461,29.5232327 45.3107251,29.7287792 L34.4496497,35.1193129 C33.7280761,35.4754046 32.9340745,35.6602153 32.12942,35.6593693 C31.324827,35.6596772 30.5309462,35.4748946 29.8091902,35.1193129 L18.9581158,29.7287792 C18.4276948,29.5232327 18.0591656,29.0353562 18.0064716,28.4689472 C17.9537776,27.9025383 18.2259853,27.3550529 18.709387,27.0551887 C19.1927888,26.7553246 19.8042108,26.7546762 20.2882475,27.0535143 Z M33.8145868,14.3722586 L44.875682,19.6327795 C45.6458424,19.9310731 46.1534686,20.672055 46.1534686,21.4979642 C46.1534686,22.3238734 45.6458424,23.0648552 44.875682,23.3631488 L33.8145868,28.6236697 C32.7459643,29.1200145 31.5128756,29.1200145 30.4442531,28.6236697 L19.3831579,23.3631488 C18.6129976,23.0648552 18.1053713,22.3238734 18.1053713,21.4979642 C18.1053713,20.672055 18.6129976,19.9310731 19.3831579,19.6327795 L30.4442531,14.3722586 C31.5128756,13.8759138 32.7459643,13.8759138 33.8145868,14.3722586 Z"
                id="path-2-layer"
            ></path>
            <filter
                x="-5.3%"
                y="-5.4%"
                width="121.2%"
                height="121.4%"
                filterUnits="objectBoundingBox"
                id="filter-3-layer"
            >
                <feOffset
                    dx="2"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="0.5"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_login"
                transform="translate(-728.000000, -483.000000)"
                fill-rule="nonzero"
            >
                <g
                    id="icon_login_style"
                    transform="translate(710.000000, 469.000000)"
                >
                    <use
                        fill="black"
                        fill-opacity="1"
                        filter="url(#filter-3-layer)"
                        xlink:href="#path-2-layer"
                    ></use>
                    <use
                        fill="url(#linearGradient-layer)"
                        xlink:href="#path-2-layer"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
</template>
