<template>
    <svg
        width="37px"
        height="43px"
        viewBox="0 0 37 43"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_login_reload</title>
        <defs>
            <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="linearGradient-1-reload"
            >
                <stop stop-color="#E2C785" offset="0%"></stop>
                <stop stop-color="#A38543" offset="100%"></stop>
            </linearGradient>
            <path
                d="M1205.22025,674.754294 C1204.88887,681.709234 1199.0978,687.249747 1192.00546,687.249747 C1184.69893,687.249747 1178.7745,681.373813 1178.7745,674.119822 C1178.7745,667.084057 1184.35543,661.337442 1191.3629,661.006062 L1191.3629,667.249747 L1205.22429,659.749242 L1191.36694,651 L1191.36694,657.251768 C1182.27016,657.587189 1175,665.010911 1175,674.119822 C1175,683.442918 1182.6177,691 1192.0095,691 C1201.19115,691 1208.67145,683.78238 1209.00687,674.754294 L1205.22025,674.754294 Z"
                id="path-2-reload"
            ></path>
            <filter
                x="-4.4%"
                y="-3.8%"
                width="117.6%"
                height="115.0%"
                filterUnits="objectBoundingBox"
                id="filter-3-reload"
            >
                <feOffset
                    dx="2"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="0.5"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_login"
                transform="translate(-1175.000000, -651.000000)"
                fill-rule="nonzero"
            >
                <g id="icon_login_reload">
                    <use
                        fill="black"
                        fill-opacity="1"
                        filter="url(#filter-3-reload)"
                        xlink:href="#path-2-reload"
                    ></use>
                    <use
                        fill="url(#linearGradient-1-reload)"
                        xlink:href="#path-2-reload"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
</template>
