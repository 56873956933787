<template>
    <svg
        width="33px"
        height="33px"
        viewBox="0 0 33 33"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_login_phone</title>
        <defs>
            <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="linearGradient-1-phone"
            >
                <stop stop-color="#EBCF8B" offset="0%"></stop>
                <stop stop-color="#A28342" offset="100%"></stop>
            </linearGradient>
            <path
                d="M50.2888077,40.0611549 C51.5169858,37.9237351 50.8367034,36.2821113 48.6617011,34.6395663 C46.1049035,32.7085931 43.6061747,31.409745 41.8095042,33.4903118 C41.8095042,33.4903118 39.9028123,35.7601181 34.2918368,30.4485509 C27.7671983,24.2289948 30.5055347,22.0231526 30.5055347,22.0231526 C32.7747515,19.7418136 31.3322787,18.0399101 29.4291241,15.4623684 C27.5261168,12.8848635 25.6037286,12.0690626 22.8439111,14.285848 C17.5257117,18.5578503 25.0246983,28.5886162 28.8161957,32.4889559 L28.8160483,32.4892506 C28.8160483,32.4892506 34.5816283,38.4529501 38.2097887,40.4382707 L40.1507102,41.5235611 C42.9345143,42.9521062 46.0613887,43.6060439 48.2627726,42.2795246 C48.2627726,42.2795983 49.3239291,41.7337659 50.2888077,40.0611549 Z"
                id="path-2-phone"
            ></path>
            <filter
                x="-5.0%"
                y="-5.0%"
                width="120.1%"
                height="120.0%"
                filterUnits="objectBoundingBox"
                id="filter-3-phone"
            >
                <feOffset
                    dx="2"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="0.5"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_login"
                transform="translate(-731.000000, -569.000000)"
                fill-rule="nonzero"
            >
                <g
                    id="icon_login_phone"
                    transform="translate(710.000000, 556.000000)"
                >
                    <use
                        fill="black"
                        fill-opacity="1"
                        filter="url(#filter-3-phone)"
                        xlink:href="#path-2-phone"
                    ></use>
                    <use
                        fill="url(#linearGradient-1-phone)"
                        xlink:href="#path-2-phone"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
</template>
