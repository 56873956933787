import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cafd1154"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loby-item-root" }
const _hoisted_2 = {
  key: 0,
  class: "item-header-g1"
}
const _hoisted_3 = { class: "item-header-desk" }
const _hoisted_4 = { class: "item-header-ju-shoe" }
const _hoisted_5 = { class: "road-map-summary" }
const _hoisted_6 = ["data-label"]
const _hoisted_7 = ["data-label"]
const _hoisted_8 = {
  class: "road-map-summary-item",
  "data-color": "green",
  "data-label": "和"
}
const _hoisted_9 = {
  key: 0,
  class: "road-map-summary-item",
  "data-color": "red",
  "data-label": "对"
}
const _hoisted_10 = {
  key: 1,
  class: "road-map-summary-item",
  "data-color": "blue",
  "data-label": "对"
}
const _hoisted_11 = {
  key: 1,
  class: "item-header-g2"
}
const _hoisted_12 = { class: "item-header-g2-r1" }
const _hoisted_13 = { class: "item-header-desk" }
const _hoisted_14 = { class: "item-header-ju-shoe" }
const _hoisted_15 = { class: "item-header-g2-r2" }
const _hoisted_16 = { class: "road-map-summary" }
const _hoisted_17 = ["data-label"]
const _hoisted_18 = ["data-label"]
const _hoisted_19 = {
  class: "road-map-summary-item",
  "data-color": "green",
  "data-label": "和"
}
const _hoisted_20 = {
  key: 0,
  class: "road-map-summary-item",
  "data-color": "red",
  "data-label": "对"
}
const _hoisted_21 = {
  key: 1,
  class: "road-map-summary-item",
  "data-color": "blue",
  "data-label": "对"
}
const _hoisted_22 = {
  key: 2,
  class: "item-body-g1"
}
const _hoisted_23 = {
  key: 0,
  class: "is-at-this-table"
}
const _hoisted_24 = {
  key: 3,
  class: "item-body-g2"
}
const _hoisted_25 = {
  key: 0,
  class: "is-at-this-table"
}
const _hoisted_26 = {
  key: 4,
  class: "is-reserved"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_road_map = _resolveComponent("road-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.displayType === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.tableName), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["item-header-timer", _ctx.timerColor])
          }, _toDisplayString(_ctx.currentCount !== -1 ? _ctx.currentCount : ''), 3),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.mData.downLimit + '-' + _ctx.mData.upLimit), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "road-map-summary-item",
              "data-color": "red",
              "data-label": 
                        _ctx.mData.gameTypeNo.toUpperCase() === 'G002' ? '庄' : '龙'
                    
            }, _toDisplayString(_ctx.redCount), 9, _hoisted_6),
            _createElementVNode("div", {
              class: "road-map-summary-item",
              "data-color": "blue",
              "data-label": 
                        _ctx.mData.gameTypeNo.toUpperCase() === 'G002' ? '闲' : '虎'
                    
            }, _toDisplayString(_ctx.blueCount), 9, _hoisted_7),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.greenCount), 1),
            (_ctx.mData.gameTypeNo.toUpperCase() === 'G002')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.redPairCount), 1))
              : _createCommentVNode("", true),
            (_ctx.mData.gameTypeNo.toUpperCase() === 'G002')
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.bluePairCount), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.tableName) + " " + _toDisplayString(_ctx.mData.tableNo), 1),
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.mData.downLimit + '-' + _ctx.mData.upLimit), 1)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", {
                class: "road-map-summary-item",
                "data-color": "red",
                "data-label": 
                            _ctx.mData.gameTypeNo.toUpperCase() === 'G002'
                                ? '庄'
                                : '龙'
                        
              }, _toDisplayString(_ctx.redCount), 9, _hoisted_17),
              _createElementVNode("div", {
                class: "road-map-summary-item",
                "data-color": "blue",
                "data-label": 
                            _ctx.mData.gameTypeNo.toUpperCase() === 'G002'
                                ? '闲'
                                : '虎'
                        
              }, _toDisplayString(_ctx.blueCount), 9, _hoisted_18),
              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.greenCount), 1),
              (_ctx.mData.gameTypeNo.toUpperCase() === 'G002')
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.redPairCount), 1))
                : _createCommentVNode("", true),
              (_ctx.mData.gameTypeNo.toUpperCase() === 'G002')
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.bluePairCount), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["item-header-timer", _ctx.timerColor])
            }, _toDisplayString(_ctx.currentCount !== -1 ? _ctx.currentCount : ''), 3)
          ])
        ])),
    (_ctx.displayType === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createVNode(_component_road_map, {
            class: "road-map",
            mapData: _ctx.mData.expand.resultList,
            gameType: _ctx.mData.gameTypeNo.toUpperCase() === 'G002' ? 1 : 2,
            onRoadMapData: _ctx.onRoadMapDataParsed,
            beadRowCount: 10
          }, null, 8, ["mapData", "gameType", "onRoadMapData"]),
          (_ctx.isAtThisTable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, "您在此桌"))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createVNode(_component_road_map, {
            class: "road-map",
            mapData: _ctx.mData.expand.resultList,
            gameType: _ctx.mData.gameTypeNo.toUpperCase() === 'G002' ? 1 : 2,
            onRoadMapData: _ctx.onRoadMapDataParsed,
            beadRowCount: 10,
            display: 2
          }, null, 8, ["mapData", "gameType", "onRoadMapData"]),
          (_ctx.isAtThisTable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, "您在此桌"))
            : _createCommentVNode("", true)
        ])),
    (_ctx.mData.reserverd > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_26))
      : _createCommentVNode("", true)
  ]))
}