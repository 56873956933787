<template>
    <svg
        width="36px"
        height="29px"
        viewBox="0 0 36 29"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_lobby_showtype2</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_lobby"
                transform="translate(-1594.000000, -21.000000)"
                fill="#CDB170"
            >
                <g
                    id="icon_lobby_showtype2"
                    transform="translate(1594.000000, 21.000000)"
                >
                    <rect
                        id="矩形备份-4"
                        x="0"
                        y="0"
                        width="36"
                        height="7"
                        rx="1"
                    ></rect>
                    <rect
                        id="矩形备份-5"
                        x="0"
                        y="11"
                        width="36"
                        height="7"
                        rx="1"
                    ></rect>
                    <rect
                        id="矩形备份-6"
                        x="0"
                        y="22"
                        width="36"
                        height="7"
                        rx="1"
                    ></rect>
                </g>
            </g>
        </g>
    </svg>
</template>

