export const Lang = ['en', 'kor', 'zh-cn', 'zh-hk']

export enum EventBuss {
    PAGE_ON_VISIBLE = 'PAGE_ON_VISIBLE',
    WINDOW_RESIZED = 'WINDOW_RESIZED',
    TOAST_MESSAGE = 'TOAST_MESSAGE',
    SESSION_EXPIRED = 'SESSION_EXPIRED',
    SHOW_GAME_RESULT = 'SHOW_GAME_RESULT',
    DIALOG = 'DIALOG',
    AGREE_TO_AGREEMENT = 'AGREE_TO_AGREEMENT',
    REQUEST_FULL_SCREEN = 'REQUEST_FULL_SCREEN',
    SOCKET_MESSAGE = 'SOCKET_MESSAGE',
    SOCKET_CONNECT = 'SOCKET_CONNECT',
    SOCKET_CLOSE = 'SOCKET_CLOSE',
    HINT = 'HINT',
    SMALL_TOAST = 'SMALL_TOAST',
    PLACE_CHIP = 'PLACE_CHIP',
    TIP_CONCENT_DATA = 'TIP_CONCENT_DATA',
    GAMESTATUS = 'GAMESTATUS',
    PREVIEW_RESULT = 'PREVIEW_RESULT',
    START_CLIENT_TIME_OUT = 'START_CLIENT_TIME_OUT',
    MULTI_CANCEL_GAME = 'MULTI_CANCEL_GAME',
    MULTI_END_GAME = 'MULTI_END_GAME',
    MESSAGE = 'MESSAGE'
}

export const OtherApiParams = {
    jm: 0,
    skey: process.env.API_SKEY
}

/**
 * API ACT params
 */
export const ACT = {
    LOGIN: 1,
    TRIAL: 11,
    LOG_OUT: 17,
    MULTIPLE_DESK_INFO: 2,
    GET_NOTICE_AND_LIMIT: 9,
    SINGLE_DESK_INFO: 3,
    CARD_INFO: 4,
    DO_DT_BET: 7,
    DO_BAC_BET: 6,
    CHANGE_PASS: 12,
    GET_LOG: 13,
    GET_LOG_DETAILS: 15,
    MULTIPLE_BET_DESK_INFO: 18
}

/**
 * GameState Of a Desk
 */
export const GameRoundState = {
    CLOSED: 1,
    END_OF_ROUND: 4
}

/**
 * Game Result
 */
export const RESULT = {
    RED: 1,
    BLUE: 3,
    GREEN: 2
}

export enum SOCKET_MESSAGE {
    NEW_GAME = 'NEWGAME',
    USER_OUT = 'USER-OUT',
    USER_OUT_BY = 'USER-OUT-BY',
    END_GAME = 'ENDGAME',
    STOP_GAME = 'STOPGAME',
    GUNNER_VERIFY = 'GUNNER-VERIFY',
    SERVER_PONG = 'SERVER-PONG',
    CHANGE_LIMIT = 'CHANGE-LIMIT',
    BET = 'BET',
    GUNNER_BET = 'GUNNERBET',
    GUNNER_GIVEFREE = 'GUNNER-GIVEFEE',
    NEW_SHOE = 'NEWSHOE',
    CANCEL_GAME = 'CANCELGAME',
    MEMBER_PLAY = 'MEMBER-PLAY',
    MEMBER_AMOUNT = 'MEMBERAMOUNT',
    WS_EXPIRED = 'TOKEN-EXPIRED',
    TABLE_VISIT_STATUS = 'TABLE-VISIT-STATUS',
    STOP_BET = 'STOPBET'
}

export enum BetType {
    BANKER = 0,
    PLAYER = 1,
    BC_TIE = 2,
    DT_TIE = 3,
    DRAGON = 4,
    TIGER = 5,
    PLAYER_PAIR = 6,
    BANKER_PAIR = 7
}

export const HALL = {
    ALL: 'all',
    BACCARAT: 'baccarat',
    DRAGON_TIGER: 'dragontiger',
    MULTI: 'multi',
    parse(value: number): string {
        switch (value) {
            case 0:
                return this.ALL
            case 1:
                return this.BACCARAT
            case 2:
                return this.DRAGON_TIGER
            case 3:
                return this.MULTI
            default:
                return this.ALL
        }
    },
    convert(hall: string) {
        switch (hall.toLocaleLowerCase()) {
            case HALL.ALL:
                return 0
            case HALL.BACCARAT:
                return 1
            case HALL.DRAGON_TIGER:
                return 2
            case HALL.MULTI:
                return 3
            default:
                return 0
        }
    }
}
