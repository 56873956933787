
import { store, useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { abbreviateNumber } from '@/utils'
import {
    ChipOption,
    computed,
    defineComponent,
    Emitter,
    inject,
    onMounted,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    setup() {
        const store = useStore()
        const emitter = inject('emitter') as Emitter

        const langCode = ref<string>('')

        const isMobile = computed((): boolean => store.getters['isMobile'])
        const chipsArray = ref<ChipOption[]>([
            { id: 1, value: [5, 10, 20, 50, 100, 500, 1000] },
            { id: 2, value: [100, 500, 1000, 2000, 5000, 10000, 50000] },
            { id: 3, value: [1000, 2000, 5000, 10000, 50000, 100000, 150000] },
            {
                id: 4,
                value: [5000, 10000, 50000, 100000, 150000, 200000, 250000]
            }
        ])
        const activeChip = ref<number>(1)

        const toShortValue = (value: number) => {
            return abbreviateNumber(value)
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'chips'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            close()
        }

        const cancel = () => {
            close()
        }

        const setChips = (_copt: ChipOption) => {
            if (_copt.id !== chips.value.id)
                store.commit(AppMutation.SET_CHIPS, _copt)
        }

        const handleSelectLanguage = (_langCode: string) => {
            if (langCode.value !== _langCode) langCode.value = _langCode
        }

        const activeLang = computed((): string => store.getters['lang'])

        const chips = computed((): ChipOption => store.getters['chips'])

        onMounted(() => {
            langCode.value = activeLang.value
        })

        return {
            isMobile,
            chipsArray,
            activeLang,
            langCode,
            activeChip,
            chips,
            toShortValue,
            handleSelectLanguage,
            confirm,
            close,
            cancel,
            setChips
        }
    }
})
