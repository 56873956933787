<template>
    <svg
        width="36px"
        height="27px"
        viewBox="0 0 36 27"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_lobby_showtype1</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_lobby"
                transform="translate(-1513.000000, -22.000000)"
                fill="#CDB170"
            >
                <g
                    id="icon_lobby_showtype1"
                    transform="translate(1513.000000, 22.000000)"
                >
                    <rect
                        id="矩形"
                        x="0"
                        y="0"
                        width="36"
                        height="10"
                        rx="1"
                    ></rect>
                    <rect
                        id="矩形备份-3"
                        x="0"
                        y="17"
                        width="36"
                        height="10"
                        rx="1"
                    ></rect>
                </g>
            </g>
        </g>
    </svg>
</template>

