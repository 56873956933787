<template>
    <svg
        width="38px"
        height="38px"
        viewBox="0 0 38 38"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_lobby_normal</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_lobby"
                transform="translate(-1739.000000, -18.000000)"
                fill="#CDB170"
                fill-rule="nonzero"
            >
                <g
                    id="icon_lobby_normal"
                    transform="translate(1739.000000, 18.000000)"
                >
                    <polygon
                        id="路径"
                        transform="translate(34.500000, 3.500000) scale(-1, -1) translate(-34.500000, -3.500000) "
                        points="35.2 0 31 0 31 2.8 35.2 2.8 35.2 7 38 7 38 0"
                    ></polygon>
                    <polygon
                        id="路径"
                        transform="translate(34.500000, 34.500000) scale(-1, -1) translate(-34.500000, -34.500000) "
                        points="35.2 35.2 31 35.2 31 38 38 38 38 31 35.2 31"
                    ></polygon>
                    <polygon
                        id="路径"
                        transform="translate(3.500000, 3.500000) scale(-1, -1) translate(-3.500000, -3.500000) "
                        points="2.8 2.8 7 2.8 7 0 0 0 0 7 2.8 7"
                    ></polygon>
                    <polygon
                        id="路径"
                        transform="translate(3.500000, 34.500000) scale(-1, -1) translate(-3.500000, -34.500000) "
                        points="2.8 31 0 31 0 38 7 38 7 35.2 2.8 35.2"
                    ></polygon>
                    <path
                        d="M31,26.5 L31,11.5 C31,9.01485434 28.9851457,7 26.5,7 L11.5,7 C9.01485434,7 7,9.01485434 7,11.5 L7,26.5 C7,28.9851457 9.01485434,31 11.5,31 L26.5,31 C28.9851457,31 31,28.9851457 31,26.5 Z"
                        id="路径"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>