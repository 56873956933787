/**
 * get the device orientation
 * @returns string portrait | landscape
 */

import { Lang } from '@/types/global'

export function getDeviceOrientation(): 'portrait' | 'landscape' {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window.screen as any).orientation) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (window.screen as any).orientation.type.includes('landscape')
            ? 'landscape'
            : 'portrait'
    }

    // iOS/safari
    // console.log(Math.abs(+window.orientation))
    return Math.abs(+window.orientation) === 90 ? 'landscape' : 'portrait'
}

/**
 * Check if It is A mobile device or not
 * @returns bolean
 */
export function isMobile(): boolean {
    if (
        navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        return true
    }
    return false
}

/**
 * return browser language
 * @returns browser language
 */
export function getBrowserLanguage(): string {
    // const lang = (navigator.language || 'zh-cn').split('-')[0]

    // if (Lang.indexOf(lang) === -1) {
    //     return 'zh-cn'
    // }

    // return lang

    return 'zh-cn'
}

/**
 * generate random number between min and max number
 * @param min smallest posible number
 * @param max maximum pasible number
 */
export function generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export function parseBool(strValue: string): boolean {
    return !!JSON.parse(String(strValue).toLowerCase())
}

/**
 * Abrevate number into short digits
 * @param n
 * @returns
 */
export function abbreviateNumber(n: number): string {
    if (n < 1e3) return n.toString()
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'

    return n.toString()
}

/**
 * Get Item In LocalStorage
 * @param _key
 * @returns
 */
export function getCookieValue(_key: string) {
    return window.sessionStorage.getItem(_key)
}
/**
 * Set Item in LocalStorage
 * @param _key
 * @param _value
 */
export function setCookieValue(_key: string, _value: string) {
    window.sessionStorage.setItem(_key, _value)
}
