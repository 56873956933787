<template>
    <svg
        width="25px"
        height="33px"
        viewBox="0 0 25 33"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_login_pwd</title>
        <defs>
            <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="linearGradient-login_pwd"
            >
                <stop stop-color="#ECD08C" offset="0%"></stop>
                <stop stop-color="#A18341" offset="100%"></stop>
            </linearGradient>
            <path
                d="M41.7289345,25.3731609 L28.769951,25.3731609 C28.9926438,23.4953188 27.3762818,15.8609006 33.3461881,15.6749889 C39.0225145,15.4596523 38.1390994,22.6413286 38.1390994,22.6413286 L40.9190816,22.6600535 C40.9190816,22.6600535 41.7269282,13.0468123 33.3461881,13 C24.1997325,13.4641106 26.3457423,23.8858671 26.0668747,25.3731609 C26.1317432,25.3731609 26.2160053,25.3731609 26.3049487,25.3731609 C24.4799376,25.3731609 23,26.8537673 23,28.6781097 L23,39.6950513 C23,41.5200624 24.4806063,43 26.3049487,43 L41.7289345,43 C43.5539456,43 45.0338832,41.5193937 45.0338832,39.6950513 L45.0338832,28.6781097 C45.0338832,26.8537673 43.5532769,25.3731609 41.7289345,25.3731609 Z M35.1183683,34.9830584 L35.1183683,37.4921979 C35.1183683,38.0987517 34.6234953,38.5936246 34.0169416,38.5936246 C33.4103879,38.5936246 32.9155149,38.0987517 32.9155149,37.4921979 L32.9155149,34.9830584 C32.2594739,34.6012037 31.8140883,33.8983504 31.8140883,33.0851538 C31.8140883,31.8680339 32.8004904,30.8816317 34.0176103,30.8816317 C35.2347303,30.8816317 36.2211324,31.8680339 36.2211324,33.0851538 C36.2204637,33.8983504 35.775078,34.6012037 35.1183683,34.9830584 Z"
                id="path-2-login_pwd"
            ></path>
            <filter
                x="-6.8%"
                y="-5.0%"
                width="127.2%"
                height="120.0%"
                filterUnits="objectBoundingBox"
                id="filter-3-login_pwd"
            >
                <feOffset
                    dx="2"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="0.5"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_login"
                transform="translate(-733.000000, -395.000000)"
                fill-rule="nonzero"
            >
                <g
                    id="icon_login_pwd"
                    transform="translate(710.000000, 382.000000)"
                >
                    <use
                        fill="black"
                        fill-opacity="1"
                        filter="url(#filter-3-login_pwd)"
                        xlink:href="#path-2-login_pwd"
                    ></use>
                    <use
                        fill="url(#linearGradient-login_pwd)"
                        xlink:href="#path-2-login_pwd"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
</template>
