
import { giveFeeVerify } from '@/api'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import audioPlayer from '@/utils/sounds'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref
} from 'vue'

export default defineComponent({
    setup() {
        const store = useStore()
        const emitter = inject('emitter') as Emitter

        const videoIsOn = ref<boolean>(false)
        const soundEffectIsOn = ref<boolean>(false)

        const isMobile = computed((): boolean => store.getters['isMobile'])

        //#region  Vue Helpers
        onMounted(() => {
            videoIsOn.value = isVideoOn.value
            soundEffectIsOn.value = isSoundOn.value
        })
        //#endregion

        const toggleVideo = () => {
            videoIsOn.value = !videoIsOn.value
        }
        const toggleSoundEffect = () => {
            soundEffectIsOn.value = !soundEffectIsOn.value
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'media-setting'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            if (isVideoOn.value !== videoIsOn.value) {
                store.commit(AppMutation.SET_VIDEO, videoIsOn.value)
            }

            if (soundEffectIsOn.value !== isSoundOn.value) {
                const _isSoundOn = soundEffectIsOn.value ? 1 : 0
                store.commit(AppMutation.SET_SOUND, soundEffectIsOn.value)
                if (audioPlayer) audioPlayer.isOn = _isSoundOn
            }
            close()
        }
        const cancel = () => {
            close()
        }

        const isVideoOn = computed((): boolean => store.getters['videoIsOn'])

        const isSoundOn = computed((): boolean => store.getters['canPlaySound'])

        return {
            isMobile,
            videoIsOn,
            soundEffectIsOn,
            confirm,
            close,
            cancel,
            toggleVideo,
            toggleSoundEffect
        }
    }
})
