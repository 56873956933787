<template>
    <svg
        width="19px"
        height="17px"
        viewBox="0 0 19 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_setting_logout</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_game-result备份"
                transform="translate(-1794.000000, -323.000000)"
                fill="#533E0B"
                fill-rule="nonzero"
            >
                <path
                    d="M1802.90734,323 C1803.2358,323 1803.50116,323.265364 1803.50116,323.593822 C1803.50116,323.922281 1803.2358,324.187645 1802.90734,324.187645 L1802.90734,324.187645 L1796.37529,324.187645 C1795.72023,324.187645 1795.18764,324.72023 1795.18764,325.37529 L1795.18764,325.37529 L1795.18764,337.25174 C1795.18764,337.9068 1795.72023,338.439385 1796.37529,338.439385 L1796.37529,338.439385 L1802.90734,338.439385 C1803.2358,338.439385 1803.50116,338.704749 1803.50116,339.033207 C1803.50116,339.361665 1803.2358,339.62703 1802.90734,339.62703 L1802.90734,339.62703 L1796.37529,339.62703 C1795.06331,339.62703 1794,338.563716 1794,337.25174 L1794,337.25174 L1794,325.37529 C1794,324.063313 1795.06331,323 1796.37529,323 L1796.37529,323 Z M1806.07872,324.792602 L1812.80376,330.871859 C1813.06541,331.109388 1813.06541,331.517641 1812.80376,331.753315 L1806.07872,337.832572 C1805.31603,338.522891 1804.09498,337.981028 1804.09498,336.951117 L1804.09498,334.523868 C1804.09498,334.442217 1804.02818,334.375412 1803.94653,334.375412 L1798.15676,334.375412 C1797.8283,334.375412 1797.56293,334.110048 1797.56293,333.78159 L1797.56293,328.84544 C1797.56293,328.516982 1797.8283,328.251618 1798.15676,328.251618 L1803.94653,328.251618 C1804.02818,328.251618 1804.09498,328.184813 1804.09498,328.103162 L1804.09498,325.674057 C1804.09498,324.644146 1805.31603,324.102283 1806.07872,324.792602 Z"
                    id="icon_setting_logout"
                ></path>
            </g>
        </g>
    </svg>
</template>