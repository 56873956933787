<template>
    <svg
        width="19px"
        height="19px"
        viewBox="0 0 19 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_setting_rule</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_game-result备份"
                transform="translate(-1794.000000, -124.000000)"
                fill="#533E0B"
                fill-rule="nonzero"
            >
                <path
                    d="M1803.5,124 C1808.72498,124 1813,128.275019 1813,133.5 C1813,138.725 1808.72498,143 1803.5,143 C1798.27502,143 1794,138.725 1794,133.5 C1794,128.275019 1798.27502,124 1803.5,124 Z M1803.5,125.187509 C1798.86875,125.187509 1795.18751,128.868745 1795.18751,133.5 C1795.18751,138.131255 1798.86875,141.812509 1803.5,141.812509 C1808.13125,141.812509 1811.81249,138.131255 1811.81249,133.5 C1811.81249,128.868745 1808.13125,125.187509 1803.5,125.187509 Z M1803.5,131.956255 C1804.21251,131.956255 1804.68749,132.431255 1804.68749,133.025019 L1804.68749,133.025019 L1804.68749,138.131255 C1804.68749,138.725019 1804.21249,139.2 1803.5,139.2 C1802.78749,139.2 1802.31251,138.725 1802.31251,138.131236 L1802.31251,138.131236 L1802.31251,133.025 C1802.31251,132.431236 1802.78751,131.956255 1803.5,131.956255 Z M1803.5,127.8 C1804.21251,127.8 1804.68749,128.275 1804.68749,128.987509 C1804.68749,129.700019 1804.21249,130.175 1803.5,130.175 C1802.78749,130.175 1802.31251,129.7 1802.31251,128.987509 C1802.31251,128.275 1802.78751,127.8 1803.5,127.8 Z"
                    id="icon_setting_rule"
                ></path>
            </g>
        </g>
    </svg>
</template>